import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f1279576 = () => interopDefault(import('..\\pages\\access-your-card.vue' /* webpackChunkName: "pages_access-your-card" */))
const _12b63d34 = () => interopDefault(import('..\\pages\\card-print.vue' /* webpackChunkName: "pages_card-print" */))
const _6b10ceb6 = () => interopDefault(import('..\\pages\\program-requirements\\index.vue' /* webpackChunkName: "pages_program-requirements_index" */))
const _39bb564c = () => interopDefault(import('..\\pages\\rebate-form.vue' /* webpackChunkName: "pages_rebate-form" */))
const _1666d2c8 = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages_unlock" */))
const _606e3132 = () => interopDefault(import('..\\pages\\view-your-account.vue' /* webpackChunkName: "pages_view-your-account" */))
const _53c62056 = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _53a9f154 = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _53719350 = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _5355644e = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _0b032b16 = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _6eed1cb8 = () => interopDefault(import('..\\pages\\program-requirements\\mm1.vue' /* webpackChunkName: "pages_program-requirements_mm1" */))
const _1924bf0d = () => interopDefault(import('..\\pages\\program-requirements\\program-requirement.vue' /* webpackChunkName: "pages_program-requirements_program-requirement" */))
const _291cdc6b = () => interopDefault(import('..\\pages\\program-requirements\\remicade.vue' /* webpackChunkName: "pages_program-requirements_remicade" */))
const _30f5ce1c = () => interopDefault(import('..\\pages\\program-requirements\\rybrevant.vue' /* webpackChunkName: "pages_program-requirements_rybrevant" */))
const _0eab098d = () => interopDefault(import('..\\pages\\program-requirements\\simponiAria.vue' /* webpackChunkName: "pages_program-requirements_simponiAria" */))
const _ab2f1642 = () => interopDefault(import('..\\pages\\program-requirements\\stelara-standard.vue' /* webpackChunkName: "pages_program-requirements_stelara-standard" */))
const _2438a2df = () => interopDefault(import('..\\pages\\program-requirements\\tremfya-standard.vue' /* webpackChunkName: "pages_program-requirements_tremfya-standard" */))
const _7cacdc04 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/access-your-card",
    component: _f1279576,
    name: "access-your-card"
  }, {
    path: "/card-print",
    component: _12b63d34,
    name: "card-print"
  }, {
    path: "/program-requirements",
    component: _6b10ceb6,
    name: "program-requirements"
  }, {
    path: "/rebate-form",
    component: _39bb564c,
    name: "rebate-form"
  }, {
    path: "/unlock",
    component: _1666d2c8,
    name: "unlock"
  }, {
    path: "/view-your-account",
    component: _606e3132,
    name: "view-your-account"
  }, {
    path: "/errors/400",
    component: _53c62056,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _53a9f154,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _53719350,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _5355644e,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _0b032b16,
    name: "errors-500"
  }, {
    path: "/program-requirements/mm1",
    component: _6eed1cb8,
    name: "program-requirements-mm1"
  }, {
    path: "/program-requirements/program-requirement",
    component: _1924bf0d,
    name: "program-requirements-program-requirement"
  }, {
    path: "/program-requirements/remicade",
    component: _291cdc6b,
    name: "program-requirements-remicade"
  }, {
    path: "/program-requirements/rybrevant",
    component: _30f5ce1c,
    name: "program-requirements-rybrevant"
  }, {
    path: "/program-requirements/simponiAria",
    component: _0eab098d,
    name: "program-requirements-simponiAria"
  }, {
    path: "/program-requirements/stelara-standard",
    component: _ab2f1642,
    name: "program-requirements-stelara-standard"
  }, {
    path: "/program-requirements/tremfya-standard",
    component: _2438a2df,
    name: "program-requirements-tremfya-standard"
  }, {
    path: "/",
    component: _7cacdc04,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
