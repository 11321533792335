import { Mutation, Action } from 'vuex-module-decorators';
import { Resolve } from 'vue-di';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import {
	DebitCardInfoResponseModel,
	EnrollmentHybridPatientSearchDemographicsModel,
	EnrollmentPatientLookupRequestModel,
	GetDebitCardDetailRequestModel,
	RedemptionListRequestModel,
} from '@trialcard/apigateway.models';
import { EdgeCouponApi, EdgeProgramApi, EdgePlpApi, EdgeDebitCardApi } from '@trialcard/apigateway.client';
import moment from 'moment';
import LayoutModule from './layout';
import { Settings } from '~/services/settings';



@InjectModule({ stateFactory: true }, module)
export default class EnrollmentModule extends InjectVuexModule {

	@Resolve
	public couponApi!: EdgeCouponApi;

	@Resolve
	public plpApi!: EdgePlpApi;

	@Resolve
	public debitCardApi!: EdgeDebitCardApi;

	@Resolve
	public layout!: LayoutModule;

	@Resolve
	public settings!: Settings;

	@Resolve
	public programApi!: EdgeProgramApi;

	defaultMemberNumber = '';
	defaultTcServicePrgId = 0;
	defaultLatestClaimDate = '';
	defaultPatientDetails = {
		firstName: '',
		lastName: '',
		dateOfBirth: '',
		postalCode: '',
	} as EnrollmentHybridPatientSearchDemographicsModel;
	defaultVdcDetails = {
		vmcNumber: '',
		last4: '',
		effectiveDate: undefined,
		expirationDate: undefined,
		balance: 0,
		vmcCvc: '',
		vmcUsedCount: 0,
		vmcValidThroughDate: undefined,
	} as DebitCardInfoResponseModel;

	memberNumber = this.defaultMemberNumber;
	tcServicePrgId = this.defaultTcServicePrgId;
	patientDetails = this.defaultPatientDetails;
	vdcDetails = this.defaultVdcDetails;
	latestClaimDate = this.defaultLatestClaimDate;
  static vdcDetails: any;

	@Mutation
	public setMemberNumber(memberNumber: string) {
		this.memberNumber = memberNumber;
	}

	@Mutation
	public setTcServicePrgId(tcServicePrgId: number) {
		this.tcServicePrgId = tcServicePrgId;
	}

	@Mutation
	public setPatientDetails(patientDetails: typeof EnrollmentModule.prototype.patientDetails) {
		this.patientDetails = patientDetails;
	}

	@Mutation
	public setVdcDetails(vdcDetails: typeof EnrollmentModule.prototype.vdcDetails) {
		this.vdcDetails = vdcDetails;
	}

	@Mutation
	public setLatestClaimDate(latestClaimDate: string) {
		this.latestClaimDate = latestClaimDate;
	}

	@Action({ rawError: true })
	public clearStore() {
		this.setMemberNumber(this.defaultMemberNumber);
		this.setTcServicePrgId(this.defaultTcServicePrgId);
		this.setPatientDetails(this.defaultPatientDetails);
		this.setVdcDetails(this.defaultVdcDetails);
		this.setLatestClaimDate(this.defaultLatestClaimDate);
	}

	@Action({ rawError: true })
	public async getAllData(memberNumber: string) {
		let allSuccessful = false;
		try {
			await this.checkMemberNumber(memberNumber);
			await Promise.all([
				this.getPatientDetails(memberNumber),
				this.getVDCDetails(memberNumber),
				this.getLatestClaimDate(memberNumber),
			]);
			allSuccessful = true;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error('Error from getting data:', error);
		}
		return allSuccessful;
	}

	@Action({ rawError: true })
	public async checkMemberNumber(memberId: string) {
		const request = { memberId };
		const response = await this.couponApi.memberMemberNumberStatusWithProgram(request);
		const program = response?.data?.data?.tcServicePrgDesc?.split(' ')[0];
		const tcServicePrgId = response?.data?.data?.tcServicePrgId;
		const tcServicePrgDescForStelaraStandard = 'Stelara - IBM';
		const tcServicePrgDescFortremfyaStandard = 'Tremfya Savings Program Medical';
		const tcServicePrgDescForSimponiAria = 'Simponi Aria';
		const tcServicePrgDescForRemicadeUAT = 'Remicade - IBM'
		const tcServicePrgDescForRemicadeProd = 'Remistart Hybrid Bulk Member ID Numbers Quickpath'
        if (program  && tcServicePrgId && response?.data?.data?.tcServicePrgDesc === "Janssen Carepath Multiple Myeloma Medical Program Quickpath"){
            this.layout.setProgram("mm1");
			this.setTcServicePrgId(tcServicePrgId);
        }
		else if(program  && tcServicePrgId && response?.data?.data?.tcServicePrgDesc?.toLowerCase().includes(tcServicePrgDescForStelaraStandard.toLowerCase())){
			const stelaraStandardState = 'stelaraStandard';
			this.layout.setProgram(stelaraStandardState);
			this.setTcServicePrgId(tcServicePrgId);
		}
		else if(program  && tcServicePrgId && response?.data?.data?.tcServicePrgDesc?.toLowerCase().includes(tcServicePrgDescFortremfyaStandard.toLowerCase())){
			const tremfyaStandardState = 'tremfyaStandard';
			this.layout.setProgram(tremfyaStandardState);
			this.setTcServicePrgId(tcServicePrgId);
		}
		else if(program  && tcServicePrgId && response?.data?.data?.tcServicePrgDesc?.toLowerCase().includes(tcServicePrgDescForSimponiAria.toLowerCase())){
			const simponiAriaState = 'simponiAria';
			this.layout.setProgram(simponiAriaState);
			this.setTcServicePrgId(tcServicePrgId);
		}
		else if(program  && tcServicePrgId && (response?.data?.data?.tcServicePrgDesc?.toLowerCase().includes(tcServicePrgDescForRemicadeUAT.toLowerCase()) || response?.data?.data?.tcServicePrgDesc?.toLowerCase().includes(tcServicePrgDescForRemicadeProd.toLowerCase()))){
			const remicadeState = 'remicade';
			this.layout.setProgram(remicadeState);
			this.setTcServicePrgId(tcServicePrgId);
		}
		else if (program && tcServicePrgId) {
			this.layout.setProgram(program.toLowerCase() as 'tremfya' | 'stelara' | 'erleada' | 'pah'| 'rybrevant' | 'simponi' | 'remicade');
			this.setTcServicePrgId(tcServicePrgId);
		} else {
			throw new Error('Checking memberNumber Failed');
		}
	}

	@Action({ rawError: true })
	public async getPatientDetails(memberNumber: string) {
		const request = { memberNumber } as EnrollmentPatientLookupRequestModel;
        // Hotfix to solve temporary stelara standard program id issue
        const programId = this.tcServicePrgId === 1684 ? 94 : this.tcServicePrgId;
		const response = await this.plpApi.patientPatientLookup(request, programId.toString());
		if (response && response.data && response.data.data) {
			this.setPatientDetails(response.data.data.demographics);
		} else {
			throw new Error('Getting Patient Details Failed');
		}
	}

	@Action({ rawError: true })
	public async getVDCDetails(memberNumber: string) {
		const request = { memberNumber } as GetDebitCardDetailRequestModel;
		const response = await this.debitCardApi.debitCardGetDebitCardDetail(request);
		if (response.data && response.data.data) {
			response.data.data.vmcNumber = response.data.data?.vmcNumber!.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ')
			this.setVdcDetails(response.data.data);
		} else {
			throw new Error('Getting VDC details failed');
		}
	}

	public async getLatestClaimDate(memberNumber: string, dateOfService?: string) {
		if (!dateOfService) {
			// Set dateOfService to the beginning of the current year for latest claim data
			dateOfService = `01-01-${(new Date()).getFullYear()}`
		}
		const request = {
			programId: this.tcServicePrgId,
			memberNumber,
			dateOfService,
		} as RedemptionListRequestModel;
		const response = await this.couponApi.redemptionFullRedemptionList(request);
		if (response.data && response.data.data) {
			// If we get a response but no claims, call this function with a dateOfService 1 year earlier.
			// Only going back as far as 01-01-2020
			const yearArr = dateOfService.split('-');
			const year = Number(yearArr[yearArr.length - 1]) - 1;
			const { claims } = response.data.data;
			if (claims.length > 0) {
				// Sort claims by date.  Newest claim will be first in the array
				claims.sort(function (a, b) {
					const dateA = new Date(a.dateProcessed!) as any;
					const dateB = new Date(b.dateProcessed!) as any;
					return dateB - dateA;
				});
				const claimDate = claims[0].dateProcessed || '';
				this.setLatestClaimDate(moment(claimDate).format('MM/DD/YYYY'));
			} else if (year >= 2020) {
				await this.getLatestClaimDate(memberNumber, `01-01-${year}`);
			} else {
				this.setLatestClaimDate('N/A');
			}
		} else {
			throw new Error('Getting latest claim date failed');
		}
	}
}
